body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.print-only {
  display: none
}

@media print {
  html,
  body {
    font-family: "Inter", sans-serif;
    font-size: initial !important;
    height: initial !important;
    overflow: initial !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .print-only,
  .section-column,
  .section-column-content {
    display: block !important;
    overflow: visible !important;
    box-shadow: none !important;
  }

  @page {
    margin: 24px 24px !important;
    size: letter !important;

    @bottom-right {
      content: "Page " counter(page) " of " counter(pages)
    }
  }

  .prescription-page {
    page-break-before: always !important;
    position: relative !important;
  }

  .prescription-content {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
  }

  .prescription-header,
  .prescription-footer {
    width: 100% !important;
    height: 100px !important;
    position: absolute !important;
    left: 0 !important;
  }

  .prescription-header {
    top: 0 !important;
  }

  .prescription-footer {
    bottom: 0 !important;
  }

  .prescription-header:not(:first-of-type),
  .prescription-footer:not(:first-of-type) {
    display: none !important;
  }

  .prescription-header-space {
    height: 100px !important;
  }

  .prescription-label {
    color: #70767B !important;
  }

  .lab-and-investigation-footer {
    padding-left: 8px !important;
  }

  .vital-modal-table {
    width: 30% !important;
  }

  .modal {
    .labsAndInvestigationModal-paper {
      zoom: 60%;
      display: block !important;
      overflow-y: visible !important;
      .labsAndInvestigationModal-content {
        display: block !important;
        overflow-y: visible !important;
      }
    }
  }

  [data-testid="lab-and-investigation-abnormal-chip"] {
    color: #C51A24 !important;
  }

  [data-testid="editLabAndInvestigation-paper"] {
    zoom: 60%;
  }

  [name="reviewed"],
  [name="markAsReviewed"],
  [name="SaveButton"],
  [name="dialog_secondary"],
  [name="saveLabAndInvestigation"],
  [name="lab-and-investigation-graphButton"],
  [name="vitals_print_button"],
  [name="dialog-header-close-button"],
  [data-testid="editLabAndInvestigation-backbutton"],
  [data-testid="editLabAndInvestigationModal-close-button-icon"],
  [data-testid="labs-and-investigations-print-icon-button-print-button"],
  [data-testid="editLabAndInvestigation-history-button"],
  [data-testid="editLabAndInvestigationModal-history-button"],
  [data-testid="labAndInvestigation-historyMenu"],
  .vitals-measurements,
  .simple-start-end-date-picker,
  .select-vitals-form-control
  {
    display: none !important;
  }
}
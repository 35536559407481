:root{
    --fv-accent-color: #0F6558;
    --fv-accent-btn-hover-bg: rgba(15, 101, 88,0.08);

    .fv__ui-layer{
        z-index: 1300;
    }
}
#pdf-ui, .foxit-pdf-viewer-container {
    width: 100%;
    height: 100%;

    .fv__ui-grouplist-item>:first-child {
        margin-left: 4px;
    }
    .fv__ui-grouplist-item>* {
        clear: both;
        margin-right: 4px;
    }
    .fv__ui-sidebar-ctls{
        overflow: hidden;
    }
    .group-text {
        margin-top: 4px;
        margin-bottom: 4px;
        .small-button {
            justify-content: center;
            padding: 4px;
            height: 24px
        }
    }

    .fx-ribbon-item {
        padding: 0 5px 5px 5px;

        .fx-ribbon-item-icon {
            height: 28px;

            .fx-icon {
                height:24px;
                width: 24px;
                & svg{
                    height: 24px;
                    width: 24px;
                }
            }
        }
        .fx-ribbon-item-text {
            display: flex;
            gap:2px;
            height: 14px;
            justify-content: center;
        }
    }

}
